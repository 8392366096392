/**
  * Usage: dateString | localDateTime:'format'
 **/

import { Pipe, PipeTransform, inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { SessionService } from './session.service';
import { UtilsService } from '../services/utils';

@Pipe({
    name: 'localDateTime',
    standalone: true
})
export class LocalDateTimePipe implements PipeTransform {
	private session = inject(SessionService);
	private _utils: UtilsService = inject(UtilsService);


	transform(value: any, format?: string): string {

		if (!value) { return ''; }
		if (!format) { format = 'dd.MM.yyyy hh:mm:ss'; }

		return this._utils.convertUTCDateTimeToLocalWithUserFormat(value);
		// return luxonDateTime.toFormat('LLL d, yyyy hh:mm:ss');
		// return formatDate(value, format, this.session.locale);
	}
}
